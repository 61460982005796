<template>
    <div class="box">
        <div class="frame_box">
            <div @click="closeModal" class="close_icon"><img src="../assets/images/close.png" alt=""></div>
            <div class="title_name">加入强叔</div>
            <div class="info_box">
                <div class="info_left">
                    <div>姓名</div>
                    <div><input class="phone_input" v-model="formData.username" placeholder="请输入姓名" type="text"></div>
                </div>
                <div class="info_right">
                    <div>手机</div>
                    <div><input class="phone_input" v-model="formData.phone" placeholder="请输入手机号" type="number"></div>
                </div>
            </div>
            <div class="info_box">
                <div class="info_left">
                    <div>所在省份</div>
                    <div>
                        <Select @on-change="provinceChange" placeholder="请选择省份" v-model="province">
                            <Option v-for="item in cityList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>
                    </div>
                </div>
                <div class="info_right">
                    <div>所在城市</div>
                    <div>
                        <Select @on-change="cityChange" placeholder="请选择城市" v-model="city">
                            <Option v-for="item in cityList1" :value="item.id" :key="item.id">{{ item.name }}</Option>
                        </Select>
                    </div>
                </div>
            </div>
            <div class="post_box">
                <div>选择岗位</div>
                <div>
                    <RadioGroup v-model="formData.job_id" class="post_radio">
                        <Radio v-for="(item,index) of jobList" :key="index" :label="item.id">{{item.job_name}}</Radio>
                    </RadioGroup>
                </div>
            </div>
            <div @click="submitBtn" class="submit_btn">确认提交</div>
        </div>
    </div>
</template>

<script>
    import {job_list,province_list,get_city,sign} from '@/api/index.js'
    export default {
        data(){
            return{
                show: true,
                count: '',
                timer: null,
                cityList: [],
                cityList1: [],
                jobList:[],
                formData:{
                    username:'',
                    phone:'',
                    province:'',
                    city:'',
                    job_id:''
                },
                province:'',
                city:'',
            }
        },
        created(){
            this.job_list()
            this.province_list()
        },
        methods:{
            job_list(){
                job_list().then(res=>{
                    this.jobList  = res.data
                })
            },
            province_list(){
                province_list().then(res=>{
                    this.cityList = res.data
                })
            },
            provinceChange(e){
                this.cityList.forEach(item=>{
                    if (e==item.id) {
                        this.formData.province = item.name
                    }
                })
                this.formData.city = ''
                this.city = ''
                get_city({id:e}).then(res=>{
                    this.cityList1 = res.data
                })
            },
            cityChange(e){
                this.cityList1.forEach(item=>{
                    if (e==item.id) {
                        this.formData.city = item.name
                    }
                })
            },
            submitBtn(){
                sign(this.formData).then(res=>{
                    this.$Message.success(res.message)
                    this.closeForm()
                    this.closeModal()
                }).catch(err=>{
                    this.$Message.error(err.message)
                })
            },
            closeForm(){
                this.formData = {
                    username:'',
                    phone:'',
                    province:'',
                    city:'',
                    job_id:''
                }
                this.province = ''
                this.city = ''
            },
            getCode(){
                const TIME_COUNT = 59;
                if (!this.timer) {
                    this.count = TIME_COUNT;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }else{
                    this.$Message.success('验证码已发送')
                }
            },
            closeModal(){
                this.$emit('closepop1',false)
            },
        }
    }
</script>

<style scoped>
.box{
    position: fixed;
    z-index: 999999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.frame_box{
    position: relative;
    width: 700px;
    background: #FFF;
    border-radius: 10px;
    overflow: hidden;
}
.close_icon{
    position: absolute;
    top: 15px;
    right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.close_icon img{
    width: 100%;
    height: 100%;
}
.title_name{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #FFF;
    background: #A91800;
    padding: 10px 0;
}
.peoplenum{
    text-align: center;
    padding: 5px 0;
    color: #999;
}
.people_color{
    color: red;
}
.info_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
}
.post_box{
    padding: 20px 36px;
}
.post_radio{
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.info_left{
    width: 40%;
}
.phone_input{
    outline: none;
    border: none;
    background: #F7F7F7;
    line-height: 40px;
    padding: 0 10px;
    width: 100%;
    margin-top: 10px;
}
.info_right{
    width: 40%;
}
.yzm_box{
    width: 100%;
    height: 40px;
    background: #F7F7F7;
    margin-top: 10px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}
.yzm_Input{
    width: 60%;
    outline: none;
    border: none;
    background: #F7F7F7;
    padding-right: 20px;
}
.yzm_txt{
    width: 40%;
    text-align: center;
    border-left: 1px solid #999;
    cursor: pointer;
    color: #999;
}
.submit_btn{
    background: #A91800;
    color: #FFF;
    width: 260px;
    text-align: center;
    padding: 15px 0;
    margin: 0 auto 20px;
    border-radius: 5px;
    margin-top: 80px;
    cursor: pointer;
}
.radio_box{
    background: #F8EDEB;
    width: 260px;
    margin: 10px auto 20px;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rido_box{
    display: flex;
    align-items: center;
}
.rido_box img{
    width: 16px;
    height: 16px;
    margin-right: 10px;
}
.receive_txt{
    color: #A91800;
    cursor: pointer;
}
</style>

<style>
    .ivu-select{
        width: 100%;
    }
    .ivu-select-single .ivu-select-selection{
        background: #F7F7F7;
        outline: none;
        border: none;
        height: 40px;
        display: flex;
        align-items: center;
    }
    .ivu-radio-checked .ivu-radio-inner{
        border-color: #A91800;
    }
    .ivu-radio-inner:after{
        background: #A91800;
    }
    .ivu-radio-inner{
        border: 1px solid #A91800;
    }
</style>