<template>
    <div>
        <HeaderNavigation :checkValue="4" :info="$store.state.infos" />
        <div>
            <div :style="'background: url('+banner+');background-size: 100% 100%;'" class="title_box">
                <div>
<!--                    <div class="title_text">加入我们</div>-->
                    <div class="phone_box">
                        <div class="phone_text">招聘热线</div>
                        <div class="phone_num">{{info.web_site_recruit}}</div>
                    </div>
                </div>
            </div>
            <div class="recruitment_box">
                <div class="left_box">
                    <div class="recruitment_title">招聘岗位</div>
                    <div class="recruitment_content" v-html="joinData.recruit_positions">
                        <!-- {{joinData.recruit_positions}} -->
                        <!-- 服务员 传菜员 保洁员 洗碗工 后厨 -->
                    </div>
                </div>
                <div class="right_box">
                    <div class="recruitment_title">岗位要求</div>
                    <div class="recruitment_content" v-html="joinData.job_requirement">
                        <!-- {{joinData.job_requirement}} -->
                        <!-- <div>1. 18-45岁，学历不限</div>
                        <div>2. 身体状况符合国家餐饮行列要求</div>
                        <div>3. 无不良记录和嗜好</div> -->
                    </div>
                </div>
            </div>
            <div class="treatment_box">
                <div class="treatment_title">我们的待遇</div>
                <div class="treatment_content">
                    <div v-for="(item,index) of postList" :key="index" :style="index+1==4 || index+1==8?'border:none':''" class="item_content">
                        <div><span class="money_style">{{item.job_wages}}</span>元/月</div>
                        <div v-html="item.job_treatment"></div>
                        <!-- <div class="addres_text">
                            <span v-for="(j,k) of item.address" :key="k">{{j}}<span v-if="k+1!=item.address.length">,</span></span>
                        </div> -->
                        <div class="addres_text">{{item.job_address}}</div>
                    </div>
                </div>
            </div>
            <div class="treatment_box">
                <div class="treatment_title">我们的福利</div>
                <div class="welfare_content" v-html="joinData.welfare"></div>
            </div>
        </div>
        <BottomNavigation :info="$store.state.infos" />
        <RightMenu :info="$store.state.infos" :type="5" @openpop="openpop" />
        <JoinBulletFrame v-if="JoinModal" @closepop="closepop" />
        <SignUp v-if="SignUpModal" @closepop1="closepop1" />
    </div>
</template>

<script>
    import {join_us,cateBanner,configshow} from '@/api/index.js'
    import HeaderNavigation from "@/components/header_navigation.vue";
    import BottomNavigation from "@/components/bottom_navigation.vue";
    import RightMenu from "@/components/right-menu.vue";
    import JoinBulletFrame from "@/components/joinBulletFrame.vue";
    import SignUp from "@/components/sign_up.vue";
    export default {
        components: {
            HeaderNavigation,
            BottomNavigation,
            RightMenu,
            JoinBulletFrame,
            SignUp
        },
        data(){
            return{
                JoinModal:false,
                SignUpModal:false,
                banner:'',
                postList:[],
                joinData:{},
                info:{}
            }
        },
        mounted() {
            this.configshow()
            this.cateBanner()
            this.join_us()
        },
        methods:{
            cateBanner(){
                cateBanner({id:4}).then(res=>{
                    this.banner = res.data
                })
            },
            configshow(){
                configshow().then(res=>{
                    this.info = res.data
                })
            },
            join_us(){
                join_us().then(res=>{
                    this.joinData = res.data
                    this.postList = res.data.jobs
                })
            },
            openpop(e){
                if (e==1) {
                    this.JoinModal = true
                }else{
                    this.SignUpModal = true
                }
            },
            closepop(e){
                this.JoinModal = e
            },
            closepop1(e){    
                this.SignUpModal = e
            }
        }
    }
</script>

<style scoped>
.title_box{
    width: 100%;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
   background-size:100% 100%!important;
}
.title_text{
    color: #FFF;
    font-size: 48px;
    text-align: center;
    margin-top:50px;
}
.phone_box{
  margin-top:220px;
    border: 1px solid #FFF;
    box-sizing: border-box;
    background:rgba(198, 196, 198, 0.8);
    border-radius: 12px;
    display: flex;
    overflow: hidden;
    align-items: center;
}
.phone_text{
    height: 55px;
    line-height: 55px;
    padding: 0 20px;
    background: #A91800;
    border-radius: 12px;
    color: #FFF;
    font-size: 24px;
}
.phone_num{
    font-size: 28px;
    padding: 0 20px;
    color: #000;
}

.recruitment_box{
    width: 80%;
    margin: -110px auto 0;
    display: flex;
    padding: 50px 0;
    align-items: center;
    background: #A91800;
}
.recruitment_title{
    font-size: 24px;
    color: #FFF;
}
.recruitment_content{
  text-align: left;
   padding:20px;
    color: #FFF;
    font-size: 18px;
}
.post_box{
    width: 200px;
    margin: 20px auto 0;
}
.left_box{
    width: 50%;
    height: 150px;
    text-align: center;
    border-right: 1px solid #FFF;
}
.right_box{
    width: 50%;
    height: 150px;
    text-align: center;
}

.treatment_box{
    width: 100%;
    margin: 20px 0;
}
.treatment_title{
    text-align: center;
    font-size: 28px;
    color: #000;
}
.treatment_content{
    width: 80%;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
}
.item_content{
    width: 25%;
    margin: 10px 0;
    text-align: center;
    border-right: 1px solid #eee;
}
.money_style{
    font-size: 32px;
    color: #A91800;
}
.addres_text{
    font-size: 20px;
    margin-top: 10px;
}
.welfare_content{
    width: 80%;
    line-height: 40px;
    margin: 20px auto;
}
</style>